// Generated by Framer (937e5b9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/DnNMQOJvU";

const enabledGestures = {fod8X3J2S: {hover: true}};

const cycleOrder = ["fod8X3J2S"];

const variantClassNames = {fod8X3J2S: "framer-v-gd5jui"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.6, ease: [0, 0, 1, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, name, newTab, width, ...props}) => { return {...props, mhEq2tFNk: newTab ?? props.mhEq2tFNk, PwIyszotO: link ?? props.PwIyszotO, Zr3kWRiXX: name ?? props.Zr3kWRiXX ?? "text"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;name?: string;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Zr3kWRiXX, PwIyszotO, mhEq2tFNk, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "fod8X3J2S", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6PxJx", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-gd5jui", className)} data-framer-name={"Footer Link"} layoutDependency={layoutDependency} layoutId={"fod8X3J2S"} ref={ref} style={{...style}} {...addPropertyOverrides({"fod8X3J2S-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-ap37cu"} data-styles-preset={"DnNMQOJvU"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}><Link href={PwIyszotO} openInNewTab={mhEq2tFNk} smoothScroll={false}><motion.a>text</motion.a></Link></motion.p></React.Fragment>} className={"framer-16lf5ml"} data-framer-name={"Link Small"} layoutDependency={layoutDependency} layoutId={"wutFkmPxA"} style={{"--extracted-r6o4lv": "rgb(99, 99, 99)", "--framer-paragraph-spacing": "0px", "--variable-reference-mhEq2tFNk-mEY0aWXsA": mhEq2tFNk}} text={Zr3kWRiXX} variants={{"fod8X3J2S-hover": {"--extracted-r6o4lv": "rgb(220, 220, 220)"}}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6PxJx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6PxJx .framer-n6ihsy { display: block; }", ".framer-6PxJx .framer-gd5jui { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 30px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-6PxJx .framer-16lf5ml { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-6PxJx .framer-v-gd5jui .framer-gd5jui { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6PxJx .framer-gd5jui { gap: 0px; } .framer-6PxJx .framer-gd5jui > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-6PxJx .framer-gd5jui > :first-child { margin-left: 0px; } .framer-6PxJx .framer-gd5jui > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 41
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"P15MQ9H0P":{"layout":["auto","fixed"]}}}
 * @framerVariables {"Zr3kWRiXX":"name","PwIyszotO":"link","mhEq2tFNk":"newTab"}
 */
const FramermEY0aWXsA: React.ComponentType<Props> = withCSS(Component, css, "framer-6PxJx") as typeof Component;
export default FramermEY0aWXsA;

FramermEY0aWXsA.displayName = "Footer / Footer Link";

FramermEY0aWXsA.defaultProps = {height: 30, width: 41};

addPropertyControls(FramermEY0aWXsA, {Zr3kWRiXX: {defaultValue: "text", displayTextArea: false, title: "Name", type: ControlType.String}, PwIyszotO: {title: "Link", type: ControlType.Link}, mhEq2tFNk: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramermEY0aWXsA, [...sharedStyle.fonts])